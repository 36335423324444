import axios from        'axios';
import * as Cache       from "../cache";
import * as Credentials from "../credentials";

export const PWA_update_Pending_Checkins_Count = () => {
    return dispatch => {
     dispatch( { type: 'PWA_UPDATE_PENDING_CHECKINS_COUNT'} );
    }
}

export const PWA_Load_Class_Attendees = () => {
    return dispatch => {
     dispatch( { type: 'PWA_LOAD_CLASS_ATTENDEES'} );
    }
}

export const PWA_Reset_Class_Attendees = () => {
    return dispatch => {
     dispatch( { type: 'PWA_RESET_CLASS_ATTENDEES'} );
    }
}

export const PWA_Search_Reset = () => {
    return dispatch => {
     dispatch( { type: 'PWA_SEARCH_RESET'} );
    }
}
export const PWA_Search_Students = (query) => {
    return dispatch => {
     dispatch( { type: 'PWA_SEARCH_STUDENTS', payload: query } );
    }
}
export const PWA_Cache_Reset    = () => {
    return dispatch => {
       dispatch( { type: 'PWA_CACHE_RESET'} );
    }
}
export const PWA_Get_Current_Token    = () => {
    return dispatch => {
       dispatch( { type: 'PWA_GET_CURRENT_TOKEN'} );
    }
}
export const PWA_Get_Current_Students  = () => {
    return dispatch => {
       dispatch( { type: 'PWA_GET_CURRENT_STUDENTS'} );
    }
}
export const PWA_Logoff = () => {
    return dispatch => {
     dispatch( { type: 'PWA_LOGOFF'} );
    }
}
export const PWA_Set_View = ( view ) => {
    return dispatch => {
      dispatch( { type: 'PWA_SET_VIEW', payload: view} );
    }
}
export const PWA_Set_Current_Student = ( item ) => {
    return dispatch => {
      dispatch( { type: 'PWA_SET_CURRENT_STUDENT', payload: item} );
    }
}

export const PWA_Set_Current_Location = ( item ) => {
    return dispatch => {
      dispatch( { type: 'PWA_SET_CURRENT_LOCATION', payload: item} );
    }
}

export const PWA_Process_CheckIn = ( CheckInObject, ExternalStudent ) => {
    return dispatch => {
        
        if ( ExternalStudent)
          dispatch( { type: 'PWA_APPEND_EXTERNAL_STUDENT', payload: ExternalStudent } );
       
        dispatch( { type: 'PWA_PROCESS_CHECKIN',         payload: CheckInObject   } );
    }
}

export const PWA_Login = ( credentials ) => {

    let loginURL            = `https://members.utaonline.co.uk/api/login?email=${credentials.email}&password=${credentials.password}`;
    let studentURL          = "https://members.utaonline.co.uk/api/students";

    
    const encodedLoginURL       = encodeURI( loginURL );
    const encodedstudentURL     = encodeURI( studentURL );
    
     
    return dispatch => {
        
        dispatch({ type:'PWA_LOGIN_STARTED'});
        axios.post( encodedLoginURL )
        .then( ( response ) => {
                 let token = response.data.token;
                 Credentials.PWA_Save_Credentials(credentials);
                 dispatch({ type:'PWA_LOGIN_SUCCESS',    payload: token });
                 let config = {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`
                    }
                 }
                    
                     axios.get( encodedstudentURL, config )
                     .then( ( response ) => {
                         let students  = response.data;
                         dispatch({ type:'PWA_GET_STUDENTS_SUCCESS', payload: students});
                         dispatch({ type:'PWA_GET_CURRENT_STUDENTS'});
                     }).catch(( error ) => {
                         dispatch({ type:'PWA_GET_STUDENTS_FAIL', payload: error});
                    });

        }).catch(( error ) => {
              dispatch({ type:'PWA_LOGIN_FAIL',        payload: error});
              Credentials.PWA_Reset_Credentials();
             
        });
    }
}

export const PWA_Close_Class_Success = () => {
    return dispatch => {
        dispatch( {type: 'PWA_UPLOAD_CACHE_SUCCESS'} );
    }
}

export const PWA_UpLoadCache = () => {
    
    var d = new Date();
    var n = d.toTimeString();
   // alert(`${n} - uploading cache started`);

    const upLoadURL   = "https://members.utaonline.co.uk/api/checkins"
 
    if ( Credentials.PWA_Load_Credentials() === null )  {
        return dispatch => { dispatch({ type:'PWA_UPLOAD_CACHE_NO_CREDENTIALS'}) }
    } 
 
    return dispatch => {

          let cacheSize = Cache.PWA_Cache_Get_Size()
          dispatch( {type:'PWA_UPLOAD_CACHE_STARTED', payload: cacheSize} );
        
          if ( cacheSize > 0) {

            var credentials         = Credentials.PWA_Load_Credentials();
            let loginURL            = `https://members.utaonline.co.uk/api/login?email=${credentials.email}&password=${credentials.password}`;
            const encodedLoginURL   = encodeURI( loginURL );
            axios.post( encodedLoginURL )
            .then( ( response ) => { 
        
                const newtoken = response.data.token;
                console.log(`new token - ${newtoken}`);
                const config = {
                    headers: {
                        "Accept"        : "application/json",
                        "Content-Type"  : "application/json",
                        "Authorization" : `Bearer ${newtoken}`
                    }
                }

                const PWA_Cache    = JSON.stringify( Cache.PWA_Cache_Get() );
            
                axios.post(upLoadURL, PWA_Cache, config)
                .then( ( response ) => {
                   dispatch( {type: 'PWA_UPLOAD_CACHE_SUCCESS'} );
                })
                .catch(( error ) => {
                   dispatch( {type:'PWA_UPLOAD_CACHE_FAIL', payload: error } );
                   alert(`${n} - ${error}`);
                });
            })
            .catch(( error ) => {
                dispatch( {type:'PWA_UPLOAD_CACHE_FAIL', payload: error } );
                alert(`${n} - ${error}`);
            });
                
        } else {
              dispatch({ type:'PWA_UPLOAD_CACHE_NO_ITEMS'});
        }
   
    }
    
}