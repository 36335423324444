import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'
import { faUserCheck}               from "@fortawesome/free-solid-svg-icons";
import { faUserSlash}               from "@fortawesome/free-solid-svg-icons";
import { faNotesMedical}            from "@fortawesome/free-solid-svg-icons";

import  SearchNav                   from '../navs/SearchNav';
import  SearchInput                 from '../components/SearchInput';
import { ToastContainer, toast }    from 'react-toastify';

import enterSound                  from '../sound/enterSound.mp3';


import Card       from 'react-bootstrap/Card';
import ListGroup  from 'react-bootstrap/ListGroup';
import Modal      from 'react-bootstrap/Modal';
import Button     from 'react-bootstrap/Button';
import Navbar     from 'react-bootstrap/Navbar';


function mapDispatchToProps(dispatch) {
    const actions = Object.assign( {}, AppActions );
    return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
   return { AppReducer : store.AppReducer };
}

class SearchView extends Component {

   playEnterSound      = () => {  this.audioEnter.load(); this.audioEnter.play(); }
   showToast           = ( message ) => toast.success( message, {onOpen: this.playEnterSound});

   componentDidMount() {
      this.props.actions.PWA_Search_Reset();
   }

 constructor(props){
     super(props)
     this.state                  = {  showDetails : false, item: {} }
     this.handle_Item_Click      = this.handle_Item_Click.bind(this);
     this.handle_Close_Details   = this.handle_Close_Details.bind(this);
     this.handle_CheckIn_Click   = this.handle_CheckIn_Click.bind(this);
}
 
  handle_Close_Details() {
     this.setState( {showDetails : false, item: {}} )
  }

  handle_Item_Click(event) {
      let _item = JSON.parse( event.currentTarget.getAttribute("data-item"))
      this.setState( {showDetails : true, item: _item })
  }
  
  handle_CheckIn_Click() {
 
   let  CheckInObject = { 
      instructor      :  this.props.AppReducer.currentInstructor.id,
      student         :  this.state.item.checkinCode,
      timestamp       :  Date.now(),
      formattedts     :  new Date().toLocaleString()
   }
 
    this.props.actions.PWA_Process_CheckIn( CheckInObject );
    let notificationTxt = "" + this.state.item.name  + " Checked In";
     this.showToast( notificationTxt );
   }
 

  render() {

      const searchResult = this.props.AppReducer.searchResult.map( (item, key) =>
       <ListGroup.Item className="text-center"
          action
          onClick           = { item.name ? this.handle_Item_Click : "" }
          key               = { key }
          data-item         = { JSON.stringify( item ) }
          eventKey          = { key }>

         {item.medicalNotes ?
            <FontAwesomeIcon  className="mr-2" color="red" icon={faNotesMedical}/>
            : null
         }

         {item.name ? 
            <FontAwesomeIcon className="mr-2" icon={faUserCheck}/>
          : <FontAwesomeIcon className="mr-2" icon={faUserSlash}/>
         }
          
          {item.name ? item.name  : "No Name"}  
        
        </ListGroup.Item>
      )
   
     return (

      <div>

      <ToastContainer
         position="top-right"
         autoClose={2000}
         hideProgressBar={false}
         newestOnTop
         closeOnClick={false}
         rtl={false}
         pauseOnVisibilityChange
         draggable={false}
         pauseOnHover={false}
      />

         <audio ref={r => this.audioEnter = r}>
          <source
            src={enterSound}
            type="audio/mpeg"
          />
          </audio>
      
        <Card className="mt-2 text-center">
          <Modal  show={this.state.showDetails} onHide={this.handle_Close_Details}>
          
             <Modal.Header  className="emergencyinfoAlignHeader" closeButton >
               {this.state.item.medicalNotes ? <FontAwesomeIcon  className="mr-2" color="red" icon={faNotesMedical}/> : null }  
               {this.state.item.name}
             </Modal.Header>
             
             <Modal.Body>
               {/* added in 1.32 1/7/2020 attendanceCount */}
               <span className="mb-1">Lessons Required to Grade : &nbsp;
                 { this.state.item.hasOwnProperty("attendanceCount") ? this.state.item.attendanceCount : "NA" }
               </span> 
               <br/><br/>

               <span className="mb-1">Emergency Contact:<br/>
               Name             : {this.state.item.emergencyContactName}<br/>
               Relation         : {this.state.item.emergencyContactRelationship}<br/>
               Telephone        : {this.state.item.emergencyContactTelephone}<br/>
               </span>
               <br/>

               {/* added in 1.32 1/7/2020 medicalNotes */}
               <span className="mb-1">Medical Notes:<br/>
                 {this.state.item.medicalNotes  ? this.state.item.medicalNotes  : "None" }
               </span>
       
             </Modal.Body>
             
             <Modal.Footer>
                <Button variant="danger"    onClick={this.handle_CheckIn_Click}>Check In</Button>
                <Button variant="primary"   onClick={this.handle_Close_Details}>Close</Button>
             </Modal.Footer>
          </Modal>
 
          <Card.Header>
              <SearchInput actions = { this.props.actions }></SearchInput> 
          </Card.Header>
          <Card.Body>
             <ListGroup>{searchResult}</ListGroup> 
          </Card.Body>
        </Card>
        <Navbar fixed="bottom" bg="light" variant="light">
              <SearchNav actions = { this.props.actions }></SearchNav> 
        </Navbar>

       </div>


  );

  }
}

export default connect(mapStateToProps, mapDispatchToProps)( SearchView );