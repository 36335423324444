import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';

import Card           from 'react-bootstrap/Card';
import Spinner        from 'react-bootstrap/Spinner';
import Button         from 'react-bootstrap/Button';
import Alert          from 'react-bootstrap/Alert';
import LoginForm      from '../components/LoginForm';
import Modal          from 'react-bootstrap/Modal';

import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'
import { faInfoCircle    }          from "@fortawesome/free-solid-svg-icons";
import { faCopyright     }          from "@fortawesome/free-solid-svg-icons";


function mapDispatchToProps(dispatch) {
  const actions = Object.assign( {}, AppActions );
  return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
  return {  AppReducer     : store.AppReducer };
}

function ShowSpinner(props) {
    const _showSpinner = props.showSpinner;
    if ( _showSpinner ) {
         return <Button className="mr-2" size="sm" variant="primary" disabled>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                </Button>;
    } else
        return null;
}

function ShowAlert(props) {
  const _showAlert        = props.showAlert;
  const _showAlertMessage = props.showAlertMessage;
  if ( _showAlert ) {
        return <Alert variant="danger">{_showAlertMessage}</Alert>;
  } else
      return null;
}

class LoginView extends Component {
 
  constructor(props){
     super(props)
     this.state = {
        showAbout   : false,
        version     : "1.3.3.2",
        name        : "Attendance PWA",
        copyright   : "ClubScope",
        title       : "User Login",
     }
  
     this.handle_Close_About   = this.handle_Close_About.bind(this);
     this.handle_Show_About    = this.handle_Show_About.bind(this);
  
  }

  handle_Close_About() {
    this.setState( {showAbout : false});
  }
 
  handle_Show_About() {
    this.setState( {showAbout : true});
  }


   render() {
  
     return (

      <Card className="mt-2">

          <Modal  show={this.state.showAbout} onHide={this.handle_Close_About}>
          
             <Modal.Header  className="emergencyinfoAlignHeader" closeButton >
                    <span>About {this.state.name}</span>
             </Modal.Header>
             
             <Modal.Body>

               <span className="mb-1">PWA Version :
                 <span className="ml-1" >{this.state.version}</span>
               </span> 
               <br/>

               <span className="mb-1">PWA Help    : 
                  <a className="ml-1" href="https://www.clubscope.co.uk/pwa-guide/">View Guide!</a>
               </span> 
               <br/>

               <span className="mb-1">
                <br/>  
                <FontAwesomeIcon  className="ml-1 mr-1" icon={faCopyright}/>
                2019 {this.state.copyright}
               </span> 
               <br/>

             </Modal.Body>
             
             <Modal.Footer>
                 <Button variant="primary" onClick={this.handle_Close_About}>Close</Button>
             </Modal.Footer>

          </Modal>
           
          <Card.Header className="loginView_Header">
          
              <Button className="mr-2" variant="primary" onClick={ this.handle_Show_About }>
               <FontAwesomeIcon className="mr-1" icon={faInfoCircle} />
                About
              </Button>

              <span className="ml-1 mr-3">{this.state.title}</span>
          
              <ShowSpinner showSpinner={this.props.AppReducer.showLoginSpinner }></ShowSpinner>
  
           </Card.Header>
          <Card.Body>
              <LoginForm />
          </Card.Body>
          <Card.Footer>
              <ShowAlert showAlertMessage = {this.props.AppReducer.showAlertMessage }
                         showAlert        = {this.props.AppReducer.showAlert }>
              </ShowAlert>
          </Card.Footer>
    
       </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( LoginView );