import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'

import { faPortrait   }             from "@fortawesome/free-solid-svg-icons";
import { faCamera     }             from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast }    from 'react-toastify';
import  GoHomeNav                   from '../navs/GoHomeNav';

import { faSignOutAlt }             from "@fortawesome/free-solid-svg-icons";
import { faAsterisk   }             from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch  }           from "@fortawesome/free-solid-svg-icons";

import enterSound                  from '../sound/enterSound.mp3';

import Card       from 'react-bootstrap/Card';
import Button     from 'react-bootstrap/Button';
import QrReader   from 'react-qr-reader'
import Navbar     from 'react-bootstrap/Navbar';
import axios      from 'axios';

//ver 1.3.2 add the attendees view to the right only if landscape mode on tablet
import CheckInsCurrentView       from './CheckInsCurrentView';
import * as Credentials          from "../store/credentials";


function mapDispatchToProps(dispatch) {
  const actions = Object.assign( {}, AppActions );
  return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
  return { 
            AppReducer      : store.AppReducer,
         };
}

class CheckInsProcessView extends Component {
   playEnterSound      = () =>  {this.audioEnter.load(); this.audioEnter.play() };
   showToast            = ( message ) => toast.success( message, { onOpen:  this.playEnterSound });
   showErrorToast       = ( message ) => toast.error  ( message, { onOpen:  this.playEnterSound});
                                                        
  componentDidMount() {
    this.props.actions.PWA_update_Pending_Checkins_Count();
  //  setTimeout(  function() { this.testTimer();}.bind(this), 1000);
  }

  
   constructor(props){
      super(props)
      this.state = {
      cameraIcon               : false,
      cameraMode               : 'environment',
      showCamera               : true
   }
  
   this.testTimer                          = this.testTimer.bind(this);
   this.handleError                        = this.handleError.bind(this);
   this.handleScan                         = this.handleScan.bind(this);
 
   this.showCheckinToast                   = this.showCheckinToast.bind(this);
   this.showCheckinErrorToast              = this.showCheckinErrorToast.bind(this);
   
   this.handle_Camera_Switch_Click         = this.handle_Camera_Switch_Click.bind(this);
   this.handle_Upload_Checkins             = this.handle_Upload_Checkins.bind(this);
 
}
 
   testTimer() {
    //  this.handleScan("1249291907");
   }

   handle_Camera_Switch_Click() {
      if ( this.state.cameraMode === "environment" ) {
        this.setState( { cameraMode : "user"});
      } else {
         this.setState( { cameraMode : "environment"});
      }
   }

   handle_Upload_Checkins = () => {
      this.props.actions.PWA_UpLoadCache();
   }

   showCheckinToast = () => {
      let notificationTxt = "";
      // is this student know as a clubstudent or mystudent else its an external student
      if (this.props.AppReducer.LastCheckIn.details !== undefined) {
         notificationTxt = "" + this.props.AppReducer.LastCheckIn.details.name  + " Checked In";
      } else {
         notificationTxt = "" + this.props.AppReducer.LastCheckIn.student  + " Checked In";
      }
    
      this.showToast( notificationTxt );
      setTimeout(  function() { this.setState( { showCamera : true}); }.bind(this), 1000);
   }

   showCheckinErrorToast = (message) => {
      let notificationTxt = `Check in error - ${message}`
      this.showErrorToast( notificationTxt );
      setTimeout(  function() { this.setState( { showCamera : true}); }.bind(this), 1000);
   }



   handleScan = data => {

    if (data) {
           
      this.setState( { showCamera : false});
  
      const checkInCode  = data;
      let  CheckInObject = { 
         instructor      :  this.props.AppReducer.currentInstructor.id,
         student         :  checkInCode,
         timestamp       :  Date.now(),
         formattedts     :  new Date().toLocaleString()
      }
      
      /////////////////////////////////////////////////////////////////////////////
      // 1.3.2.4 we need to see if this student is already a clubStudent or not////
      // if not already a clubStudent then append to clubStudents              ////
      /////////////////////////////////////////////////////////////////////////////

      let   jsonStr              =  localStorage.getItem('PWA_Students');
      var   jsonObj              =  JSON.parse( jsonStr);
      const filter               =  CheckInObject.student;
      let   isClubStudent        =  jsonObj.clubStudents.find(  o => o.checkinCode === filter);

      if ( isClubStudent ) {
     
           console.log("Processing a ClubStudent checkin");
           this.props.actions.PWA_Process_CheckIn( CheckInObject, null );
           this.showCheckinToast();
      
      } else {

          console.log("Processing an external student checkin");
    
          var credentials                      =  Credentials.PWA_Load_Credentials();
          let loginURL                         = `https://members.utaonline.co.uk/api/login?email=${credentials.email}&password=${credentials.password}`;
          let findExternalStudentURL           = `https://members.utaonline.co.uk/api/student?checkinCode=${CheckInObject.student}`;
          const encodedfindExternalStudentURL  = encodeURI( findExternalStudentURL );
          const encodedLoginURL                = encodeURI( loginURL );
        
            axios.post( encodedLoginURL )   // first need a new token
            .then( ( response ) => { 
                   const newtoken = response.data.token;
                   const config = {
                        headers: {
                            "Accept"        : "application/json",
                            "Content-Type"  : "application/json",
                            "Authorization" : `Bearer ${newtoken}`
                        }
                   }
                   axios.get( encodedfindExternalStudentURL, config ) // find the external student
                   .then(  (response) => {

                      if ( response.data.status === "success"){
                          var externalStudent = response.data.student; 
                          this.props.actions.PWA_Process_CheckIn( CheckInObject, externalStudent);
                          this.showCheckinToast();
                      } else {
                         this.showCheckinErrorToast(response.data.reason);
                      }

                   }).catch(( error ) => {
                      this.showCheckinErrorToast(error.message );
                   });
            }).catch(( error ) => {
               this.showCheckinErrorToast(error.message );
            });
      }
    }
  }
  
   handleError = err => {
      this.showErrorToast( err );
   }

  render() {
   
     return (

      <div>   
      
      <ToastContainer
          position="top-center"
         autoClose={1000}
         hideProgressBar={false}
         newestOnTop
         closeOnClick={false}
         rtl={false}
         pauseOnVisibilityChange
         draggable={false}
          pauseOnHover={false}
      />

      <audio ref={r => this.audioEnter = r}>
          <source
            src={enterSound}
            type="audio/mpeg"
          />
      </audio>


      <div className="tabletContainer">
        
       <div className="tabletCamWidth">
          <Card className="mt-2 text-center">
             <Card.Body>
             <Button  className="mb-1"
                       onClick={this.handle_Camera_Switch_Click}
                       variant="primary"
                       block>
                       
                       {this.state.cameraMode === "environment" ? <FontAwesomeIcon className="mr-2" icon={faCamera} />
                       : <FontAwesomeIcon className="mr-2" icon={faPortrait} />}
                       Switch Camera
               </Button>

            { this.state.showCamera ?  
              <QrReader
                style      ={{ width: '100%' }}
                delay      = {300}
                onError    = {this.handleError}
                onScan     = {this.handleScan}
                facingMode = {this.state.cameraMode} />
               
            : 
               <div class="uploadspinner">
                 <FontAwesomeIcon className="fa-spin fa-10x" icon={faCircleNotch} />
               </div>
            }

         </Card.Body>
             <Card.Footer>

                   <p>Pending Checkins : { this.props.AppReducer.pending_checkins_count } </p>
               
                  { this.props.AppReducer.show_cache_upload_spinner ?
              
                    <Button onClick={ this.handle_Upload_Checkins } variant="outline-success" >
                      <FontAwesomeIcon className="fa-spin mr-1" icon={faAsterisk} />
                      Uploading Checkins
                    </Button>
                    :
                    <Button onClick={ this.handle_Upload_Checkins } variant="outline-primary" >
                     <FontAwesomeIcon className="mr-1" icon={faSignOutAlt} />
                      Upload Checkins
                    </Button>
                  }

          </Card.Footer>
          </Card>
       </div>

        <div id="hideonmobile" className="tabletAttendees">
           <CheckInsCurrentView></CheckInsCurrentView>
        </div>

      </div>


      <Navbar fixed="bottom" bg="light" variant="light">
              <GoHomeNav actions = { this.props.actions }></GoHomeNav> 
      </Navbar>
   
     </div>
  );

  }
}

export default connect(mapStateToProps, mapDispatchToProps)( CheckInsProcessView );